import { EuiCard, EuiPageBody, EuiIcon, EuiFlexGrid, EuiText, EuiSkeletonRectangle } from '@elastic/eui'
import Toast, { showToast } from '../Components/Toast';
import axios from '../axiosService';
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'

function Dashboard() {
  const server = process.env.REACT_APP_APIPATH
  const authtoken = localStorage.getItem("authtoken");
  const config = {
    headers: {
      'Authorization': "Bearer " + authtoken
    }
  }
  const [Data, setData] = useState({})

  const [IsLoading, setIsLoading] = useState(false)
  const startLoading = useCallback(() => {
    setIsLoading(true);
  }, []);

  const stopLoading = useCallback(() => {
    setIsLoading(false);
  }, []);

  const filldata = async () => {
    try {
      startLoading();
      const response = await axios.get(`${server}api/Dashboard`, config);
      if (response.status === 200) {
        const data = await response.data;
        setData(data);
      }
      stopLoading();
    } catch (error) {
      showToast(error.toString(), "error", 15000);
      stopLoading();
    }
  }

  useEffect(() => {
    filldata();
    // eslint-disable-next-line
  }, [])

  return (
    <EuiPageBody>
      <EuiFlexGrid gutterSize="l" columns={3}>
        <EuiSkeletonRectangle
          width="100%"
          borderRadius="m"
          height={167}
          isLoading={IsLoading}
          contentAriaLabel="Example description"
        >
          <EuiCard
            icon={<EuiIcon size="xl" type={'payment'} />}
            title={"Today's Donations"}
            textAlign='left'
          >
            <EuiText size="m">Total Donations : {Data.todaysDonation ? Data.todaysDonation.count : 0}</EuiText>
            <EuiText size="m">Total Amount : {Data.todaysDonation ? Data.todaysDonation.amount : 0}</EuiText>
          </EuiCard>
        </EuiSkeletonRectangle>
        <EuiSkeletonRectangle
          width="100%"
          borderRadius="m"
          height={167}
          isLoading={IsLoading}
          contentAriaLabel="Example description"
        >
          <EuiCard
            icon={<EuiIcon size="xl" type={'payment'} />}
            title={moment(new Date()).format('MMMM') + " Donations"}
            textAlign='left'
          >
            <EuiText size="m">Total Donations : {Data.monthlyDonations ? Data.monthlyDonations.count : 0}</EuiText>
            <EuiText size="m">Total Amount : {Data.monthlyDonations ? Data.monthlyDonations.amount : 0}</EuiText>
          </EuiCard>
        </EuiSkeletonRectangle>
        <EuiSkeletonRectangle
          width="100%"
          borderRadius="m"
          height={167}
          isLoading={IsLoading}
          contentAriaLabel="Example description"
        >
          <EuiCard
            icon={<EuiIcon size="xl" type={'payment'} />}
            title={moment(new Date()).format("yyyy") + " Donations"}
            textAlign='left'
          >
            <EuiText size="m">Total Donations : {Data.yearlyDonations ? Data.yearlyDonations.count : 0}</EuiText>
            <EuiText size="m">Total Amount : {Data.yearlyDonations ? Data.yearlyDonations.amount : 0}</EuiText>
          </EuiCard>
        </EuiSkeletonRectangle>
        <EuiSkeletonRectangle
          width="100%"
          borderRadius="m"
          height={167}
          isLoading={IsLoading}
          contentAriaLabel="Example description"
        >
          <EuiCard
            icon={<EuiIcon size="xl" type={'payment'} />}
            title={"All Donations"}
            textAlign='left'
          >
            <EuiText size="m">Total Donations : {Data.allDonations ? Data.allDonations.count : 0}</EuiText>
            <EuiText size="m">Total Amount : {Data.allDonations ? Data.allDonations.amount : 0}</EuiText>
          </EuiCard>
        </EuiSkeletonRectangle>
        <EuiSkeletonRectangle
          width="100%"
          borderRadius="m"
          height={112}
          isLoading={IsLoading}
          contentAriaLabel="Example description"
        >
          <EuiCard
            icon={<EuiIcon size="xl" type={'users'} />}
            title={`${Data.inquiries || 0} Pending Inquiries`}
            textAlign='left'
          />
        </EuiSkeletonRectangle>
        <EuiSkeletonRectangle
          width="100%"
          borderRadius="m"
          isLoading={IsLoading}
          contentAriaLabel="Example description"
          height={112}
        >
          <EuiCard
            icon={<EuiIcon size="xl" type={'logsApp'} />}
            title={`${Data.causes || 0} Causes`}
            textAlign='left'
          />
        </EuiSkeletonRectangle>
        <EuiSkeletonRectangle
          width="100%"
          borderRadius="m"
          isLoading={IsLoading}
          contentAriaLabel="Example description"
          height={112}
        >
          <EuiCard
            icon={<EuiIcon size="xl" type={'spacesApp'} />}
            title={`${Data.events || 0} Events`}
            textAlign='left'
          />
        </EuiSkeletonRectangle>
        <EuiSkeletonRectangle
          width="100%"
          borderRadius="m"
          isLoading={IsLoading}
          contentAriaLabel="Example description"
          height={112}
        >
          <EuiCard
            icon={<EuiIcon size="xl" type={'image'} />}
            title={`${Data.eventPhotos || 0} Event Photos`}
            textAlign='left'
          />
        </EuiSkeletonRectangle>
        <EuiSkeletonRectangle
          width="100%"
          borderRadius="m"
          isLoading={IsLoading}
          contentAriaLabel="Example description"
          height={112}
        >
          <EuiCard
            icon={<EuiIcon size="xl" type={'user'} />}
            title={`${Data.committeeMembers || 0} Committee Members`}
            textAlign='left'
          />
        </EuiSkeletonRectangle>
        <EuiSkeletonRectangle
          width="100%"
          borderRadius="m"
          isLoading={IsLoading}
          contentAriaLabel="Example description"
          height={112}
        >
          <EuiCard
            icon={<EuiIcon size="xl" type={'reporter'} />}
            title={`${Data.articles || 0} Articles`}
            textAlign='left'
          />
        </EuiSkeletonRectangle>
        <EuiSkeletonRectangle
          width="100%"
          borderRadius="m"
          isLoading={IsLoading}
          contentAriaLabel="Example description"
          height={112}
        >
          <EuiCard
            icon={<EuiIcon size="xl" type={'submodule'} />}
            title={`${Data.galleryImages || 0} Gallery Images`}
            textAlign='left'
          />
        </EuiSkeletonRectangle>
      </EuiFlexGrid>
      <Toast />
    </EuiPageBody>
  )
}

export default Dashboard

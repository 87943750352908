import React, { useEffect, useRef, useState } from 'react';
import FormInput from '../../Components/FormInput';
import { showToast } from '../../Components/Toast';
import { EuiButton, EuiDatePicker, EuiFilePicker, EuiFlexGroup, EuiFlexItem, EuiForm, EuiFormRow, EuiImage } from '@elastic/eui';
import axios from '../../axiosService';

const Add = ({ server, authtoken, startLoading, stopLoading, setIsAdding }) => {
  const [values, setValues] = useState({
    CauseID: 0,
    EventName: "",
    EventDate: null
  });
  const [Photo, setPhoto] = useState(null)
  const [previewPhoto, setPreviewPhoto] = useState(null)
  const [CauseData, setCauseData] = useState([])

  const refs = {
    CauseID: useRef(),
    EventName: useRef(),
    EventDate: useRef(),
    Photo: useRef()
  }

  const inputs = [
    {
      id: "CauseID",
      name: "CauseID",
      type: "select",
      placeholder: "Select a Cause",
      label: "Cause",
      options: [{ value: 0, text: "-- Select Cause --" }, ...CauseData]
    }, {
      id: "EventName",
      name: "EventName",
      type: "text",
      placeholder: "Enter Event Name",
      label: "Event Name"
    }
  ];

  useEffect(() => {
    if (Photo) {
      const fileExtension = Photo.name.split('.').pop().toLowerCase();

      const imageExtensions = ['jpg', 'jpeg', 'png', 'webp'];

      if (imageExtensions.includes(fileExtension)) {
        const reader = new FileReader();
        reader.onload = () => {
          setPreviewPhoto(reader.result);
        };
        reader.readAsDataURL(Photo);
      } else {
        showToast('Only Image of type ' + imageExtensions.join(",") + ' are Allowed', "error", 2500);
        setPreviewPhoto(null);
        refs.Photo.current.removeFiles();
      }
    } else {
      setPreviewPhoto(null);
    }
  }, [Photo])

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onDateChange = (date) => {
    setValues({ ...values, EventDate: date });
  };

  function ValidateControls() {
    if (+values.CauseID === 0) {
      refs.CauseID.current.focus();
      showToast('Please Select a Cause', "warning", 1500);
      return false;
    } else if (values.EventName === "") {
      refs.EventName.current.focus();
      showToast('Please Enter Event Name', "warning", 1500);
      return false;
    } else if (values.EventDate === null) {
      refs.EventDate.current.setFocus()
      showToast('Please Select a Date', "warning", 1500);
      return false;
    } else if (!Photo && !previewPhoto) {
      refs.Photo.current.fileInput.focus();
      showToast('Please Select a Image to upload', "warning", 1500);
      return false;
    }
    return true;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (ValidateControls()) {
      try {
        startLoading();

        const formData = new FormData();
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
            'Authorization': "Bearer " + authtoken,
          }
        };

        formData.append('CauseID', values.CauseID);
        formData.append('EventName', values.EventName);
        formData.append('EventDate', values.EventDate.format("YYYY-MM-DDTkk:mm:ss"));
        formData.append('Photo', Photo);

        const response = await axios.post(`${server}api/Event`, formData, config);

        if (response.status === 200) {
          showToast("New Record Added", "success", 2000);
          setIsAdding(false);
        }

        stopLoading();
      } catch (error) {
        showToast(error.toString(), "error", 15000);
        console.error(error);
        stopLoading();
      }
    }
  };

  const fillCausedata = async () => {
    startLoading();
    try {
      const response = await axios.get(`${server}api/Cause`, {
        headers: {
          'Authorization': "Bearer " + authtoken
        }
      });
      if (response.status === 200) {
        const data = await response.data;
        setCauseData(data.map(item => {
          return {
            value: item.causeID,
            text: item.causeName,
          }
        }));
      }
      stopLoading();
    } catch (error) {
      showToast(error.toString(), "error", 15000);
      stopLoading();
    }
  }

  useEffect(() => {
    fillCausedata();
    // eslint-disable-next-line
  }, [])

  return (
    <EuiForm component='form' onSubmit={handleSubmit} >
      <div style={{ padding: "5px" }}>
        {//renders controls
          inputs.map((input) => (
            <FormInput
              key={input.id}
              {...input}
              value={values[input.name]}
              onChange={onChange}
              inputRef={refs[input.name]}
            />
          ))}
        <EuiFormRow label="Event Date" fullWidth>
          <EuiDatePicker
            showTimeSelect
            id="EventDate"
            name="EventDate"
            selected={values.EventDate}
            onChange={onDateChange}
            placeholder='Please Select a Event Date'
            inputRef={refs.EventDate}
            utcOffset="00:00"
            fullWidth
          />
        </EuiFormRow>
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiFormRow label="Event Banner Image">
              <EuiFilePicker
                onChange={(files) => setPhoto(files[0])}
                ref={refs.Photo}
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem grow={false} style={{ padding: "20px" }}>
            <EuiFormRow>
              <EuiImage alt='Preview Photo' src={previewPhoto} size="m" allowFullScreen style={{ display: previewPhoto === null ? "none" : "block" }} />
            </EuiFormRow>
          </EuiFlexItem>
        </EuiFlexGroup>
        <br />
        <div style={{ display: 'flex', justifyContent: "start", gap: "20px", alignItems: "center" }}>
          <EuiButton size='m' type='submit' fill>Save</EuiButton>
          <EuiButton size='m' type='button' onClick={() => setIsAdding(false)} color='warning' fill>Cancel</EuiButton>
        </div>
      </div>
    </EuiForm>
  );
};

export default Add;

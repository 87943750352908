import React, { useCallback, useEffect, useState } from 'react'
import Toast, { showToast } from '../../Components/Toast';
import Header from './Header';
import Add from './Add';
import Loader from '../../Components/Loader';
import Table from './Table';
import { EuiButton, EuiButtonIcon, EuiFlexGroup, EuiFlexItem, EuiImage, EuiPageBody, EuiScreenReaderOnly } from '@elastic/eui';
import axios from '../../axiosService';
import ConfirmModel, { showDialog, hideDialog } from '../../Components/ConfirmModel'

function EventPhotos() {
  const server = process.env.REACT_APP_APIPATH
  const authtoken = localStorage.getItem("authtoken");
  const config = {
    headers: {
      'Authorization': "Bearer " + authtoken
    }
  }

  const [eventId, setEventID] = useState(0);
  const [isAdding, setIsAdding] = useState(false);

  const [IsLoading, setIsLoading] = useState(false)
  const startLoading = useCallback(() => {
    setIsLoading(true);
  }, []);

  const stopLoading = useCallback(() => {
    setIsLoading(false);
  }, []);

  const [Data, setData] = useState([])
  const [itemIdToExpandedRowMap, setItemIdToExpandedRowMap] = useState([]);

  function convertDateToIndianFormat(utcTimestamp) {
    const date = new Date(utcTimestamp);

    // Calculate the offset in minutes
    const offsetInMinutes = date.getTimezoneOffset();

    // Adjust for IST by subtracting the offset from the Date object
    const istDateTime = new Date(date.getTime() - offsetInMinutes * 60000);

    // Format the IST datetime
    const formattedISTDateTime = istDateTime.toLocaleString("en-IN", {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      timeZone: "Asia/Kolkata" // Ensure IST for formatting
    }).replace(',', '').toUpperCase();

    return formattedISTDateTime;
  }

  // delete function
  const deleteRecord = async (id) => {
    try { 
      startLoading();
      var response = await axios.delete(`${server}api/EventPhotos/` + id, config)
      if (response.status === 200) {
        filldata();
        showToast('Image Deleted', "success");
      }
      hideDialog();
      stopLoading();
    } catch (error) {
      showToast(error.toString(), "error", 15000);
      console.error(error);
      stopLoading();
    }
  };

  const removeImages = () => {
    setItemIdToExpandedRowMap([]);
  }

  const toggleDetails = (record) => {
    const itemIdToExpandedRowMapValues = { ...itemIdToExpandedRowMap };

    if (itemIdToExpandedRowMapValues[record.eventId]) {
      delete itemIdToExpandedRowMapValues[record.eventId];
    } else {
      itemIdToExpandedRowMapValues[record.eventId] = (
        <EuiFlexGroup style={{ overflow: "scroll", maxHeight: "310px", border: "2px solid #304973", borderRadius: "5px", padding: "5px" }} className="eui-yScroll ">
          {record.eventPhotos.length > 0 ? record.eventPhotos.map(item => {
            return (
              <EuiFlexItem grow={false} style={{ height: '284px', flexShrink: 0, width: "auto" }}>
                <div style={{ position: "relative", flex: 1 }}>
                  <EuiImage alt='Preview Image' src={server + item.photo} style={{ maxHeight: '284px', height: "100%", width: "100%" }} allowFullScreen />
                </div>
                <div style={{ position: "relative", flex: 0 }}>
                  <EuiButtonIcon
                    iconType="trash"
                    display="fill"
                    color='danger'
                    onClick={() => showDialog(item.eventPhotosId)}
                    style={{ position: "absolute", bottom: 0, right: 0, height: "35px", width: "35px", zIndex: "150" }}
                  />
                </div>
              </EuiFlexItem>
            )
          }) :
            <div style={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "row" }}>
              <EuiButton size='s' type='submit' onClick={() => AddMore(record)} fill>Add Images</EuiButton>
            </div>
          }
        </EuiFlexGroup>
      );
    }
    setItemIdToExpandedRowMap(itemIdToExpandedRowMapValues);
  };

  const columns = [
    { field: 'causeName', name: 'Cause Name', sortable: true },
    { field: 'eventName', name: 'Event Name', sortable: true },
    {
      field: 'eventDate', name: 'Event Date', sortable: true, render: (EventDate) => (
        <span>{convertDateToIndianFormat(EventDate)}</span>
      )
    },
    {
      field: 'eventPhoto', name: 'Event Banner', render: (Photo) => (
        <EuiImage size="m" src={server + Photo} alt='No Image Found' allowFullScreen hasShadow />
      )
    },
  ]

  const AddMore = (record) => {
    setEventID(record.eventId);
    setIsAdding(true);
  };

  //declare edit and delete buttons
  const actions = [
    {
      name: 'Add New Photos',
      isPrimary: true,
      description: 'Add New Photos',
      icon: 'plus',
      type: 'icon',
      onClick: AddMore,
      'data-test-subj': 'action-edit',
    },
    {
      align: 'right',
      width: '40px',
      isExpander: true,
      name: (
        <EuiScreenReaderOnly>
          <span>Expand rows</span>
        </EuiScreenReaderOnly>
      ),
      render: (record) => {
        const itemIdToExpandedRowMapValues = { ...itemIdToExpandedRowMap };

        return (
          <EuiButtonIcon
            onClick={() => toggleDetails(record)}
            aria-label={
              itemIdToExpandedRowMapValues[record.eventId] ? 'Collapse' : 'Expand'
            }
            iconType={
              itemIdToExpandedRowMapValues[record.eventId] ? 'arrowDown' : 'arrowRight'
            }
          />
        );
      },
    }
  ];

  //add buttons to columns
  const columnsWithActions = [
    ...columns,
    {
      name: 'Actions',
      actions,
    },
  ];

  //get table data
  async function filldata() {
    try {
      startLoading();
      var response = await axios.get(`${server}api/EventPhotos`, config)
      if (response.status === 200) {
        var data = response.data;
        setData(data);
        removeImages();
      }
      stopLoading();
    } catch (error) {
      showToast(error.toString(), "error", 15000);
      console.error(error);
      stopLoading();
    }
  }

  useEffect(() => {
    filldata();
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!isAdding) {
      filldata()
    }
    // eslint-disable-next-line
  }, [isAdding]);

  return (
    <EuiPageBody style={{ width: "100%", padding: "10px" }}>
      <Loader IsLoading={IsLoading} />
      {!isAdding && (
        <>
          <Header
            headerText="Event Photos Master"
            setIsAdding={setIsAdding}
          />
          <Table data={Data} columns={columnsWithActions} height={90} itemId="eventId" itemIdToExpandedRowMap={itemIdToExpandedRowMap} isExpandable={true} />
        </>
      )}
      {isAdding && (
        <Add
          server={server}
          authtoken={authtoken}
          startLoading={startLoading}
          stopLoading={stopLoading}
          setIsAdding={setIsAdding}
          eventId={eventId}
        />
      )}
      <Toast />
      <ConfirmModel title="Delete Record ?" text="The Record will be Deleted. Please Proceed With Caution !" confirmButton="Delete" cancelButton="Cancel" confirmFunction={deleteRecord} />
    </EuiPageBody>
  )
}

export default EventPhotos

import React, { useState } from 'react';
import {
    Comparators,
    EuiBasicTable
} from '@elastic/eui';

const Table = ({ data, columns, itemId, itemIdToExpandedRowMap, isExpandable, height = 50 }) => {
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [sortField, setSortField] = useState(columns[0].field);
    const [sortDirection, setSortDirection] = useState('asc');

    const onTableChange = ({ page, sort }) => {
        if (page) {
            const { index: pageIndex, size: pageSize } = page;
            setPageIndex(pageIndex);
            setPageSize(pageSize);
        }
        if (sort) {
            const { field: sortField, direction: sortDirection } = sort;
            setSortField(sortField);
            setSortDirection(sortDirection);
        }
    };

    const finddata = (
        data,
        pageIndex,
        pageSize,
        sortField,
        sortDirection
    ) => {
        let items;

        if (sortField) {
            items = data
                .slice(0)
                .sort(
                    Comparators.property(sortField, Comparators.default(sortDirection))
                );
        } else {
            items = data;
        }

        let pageOfItems;

        if (!pageIndex && !pageSize) {
            pageOfItems = items;
        } else {
            const startIndex = pageIndex * pageSize;
            pageOfItems = items.slice(
                startIndex,
                Math.min(startIndex + pageSize, data.length)
            );
        }

        return {
            pageOfItems,
            totalItemCount: data.length,
        };
    };

    const { pageOfItems, totalItemCount } = finddata(
        data,
        pageIndex,
        pageSize,
        sortField,
        sortDirection
    );

    const pagination = {
        pageIndex: pageIndex,
        pageSize: pageSize,
        totalItemCount: totalItemCount,
        pageSizeOptions: [5, 10, 25, 50, 100],
    };

    const sorting = {
        sort: {
            field: sortField,
            direction: sortDirection,
        }
    };
    const tableContainerStyle = {
        height: height + 'vh',
        overflowY: 'auto',
    };

    return (

        <div style={tableContainerStyle} className="eui-scrollBar">
            <EuiBasicTable
                items={pageOfItems}
                columns={columns}
                sorting={sorting}
                pagination={pagination}
                onChange={onTableChange}
                responsive={true}
                itemId={itemId}
                itemIdToExpandedRowMap={itemIdToExpandedRowMap}
                isExpandable={isExpandable}
                body
            />
        </div>
    );
};

export default Table;

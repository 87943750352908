import React, { useCallback, useEffect, useState } from 'react'
import Toast, { showToast } from '../Components/Toast';
import Table from '../Components/Table';
import Loader from '../Components/Loader';
import ConfirmModel, { showDialog, hideDialog } from '../Components/ConfirmModel'
import { EuiPageBody } from '@elastic/eui';
import axios from '../axiosService';

function Inquiry() {
  const server = process.env.REACT_APP_APIPATH
  const authtoken = localStorage.getItem("authtoken");
  const config = {
    headers: {
      'Authorization': "Bearer " + authtoken
    }
  }
  const [Data, setData] = useState([])

  const [IsLoading, setIsLoading] = useState(false)
  const startLoading = useCallback(() => {
    setIsLoading(true);
  }, []);

  const stopLoading = useCallback(() => {
    setIsLoading(false);
  }, []);

  const columns = [
    { field: 'rowNumber', name: 'Row Number', sortable: true },
    { field: 'name', name: 'Name', sortable: true },
    { field: 'mobileNo', name: 'MobileNo', sortable: true },
    { field: 'emailId', name: 'EmailID', sortable: true },
    { field: 'message', name: 'Message', sortable: true },
  ]

  const deleteRecord = async (record) => {
    try {
      startLoading();
      const response = await axios.delete(`${server}api/Inquiry/` + record.id, config);
      if (response.status === 200) {
        showToast(await response.data, "success");
        filldata();
        hideDialog();
      }
      stopLoading();
    } catch (error) {
      showToast(error.toString(), "error", 15000);
      stopLoading();
    }
  };

  const actions = [
    {
      name: "Delete",
      description: 'Delete this Record',
      icon: 'trash',
      color: 'danger',
      type: 'icon',
      onClick: showDialog,
      isPrimary: true,
      'data-test-subj': 'action-delete',
    }
  ];
  //add buttons to columns
  const columnsWithActions = [
    ...columns,
    {
      name: 'Actions',
      actions,
    },
  ];

  const filldata = async () => {
    try {
      startLoading();
      const response = await axios.get(`${server}api/Inquiry`, config);
      if (response.status === 200) {
        const data = await response.data;
        setData(data);
      }
      stopLoading();
    } catch (error) {
      showToast(error.toString(), "error", 15000);
      stopLoading();
    }
  }

  useEffect(() => {
    filldata();
    // eslint-disable-next-line
  }, [])

  return (
    <EuiPageBody style={{ width: "100%", padding: "10px" }}>
      <Loader IsLoading={IsLoading} />
      <Table data={Data} columns={columnsWithActions} height={70} />
      <Toast />
      <ConfirmModel title="Delete Record ?" text="The Record will be Deleted. Please Proceed With Caution !" confirmButton="Delete" cancelButton="Cancel" confirmFunction={deleteRecord} />
    </EuiPageBody>
  )
}

export default Inquiry

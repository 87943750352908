import React, { useEffect, useRef, useState } from "react";
import FormInput from "../../Components/FormInput";
import { showToast } from "../../Components/Toast";
import {
  COLORS,
  EuiButton,
  EuiFilePicker,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiIcon,
  EuiImage,
} from "@elastic/eui";
import axios from "../../axiosService";

const Add = ({ server, authtoken, startLoading, stopLoading, setIsAdding }) => {
  const [values, setValues] = useState({
    title: "",
    description: "",
    articleLink: "",
    photo: "",
  });
  const [Photo, setPhoto] = useState(null);
  const [previewPhoto, setPreviewPhoto] = useState(null);
  const [isLinkValid, setIsLinkValid] = useState(false);

  const refs = {
    title: useRef(),
    description: useRef(),
    articleLink: useRef(),
    Photo: useRef(),
  };

  const inputs = [
    {
      id: "title",
      name: "title",
      type: "text",
      placeholder: "Enter Article Title",
      label: "Title",
      inputRef: refs.title,
    },
    {
      id: "description",
      name: "description",
      type: "textarea",
      placeholder: "Enter Description",
      label: "Description",
      inputRef: refs.description,
    },
  ];

  //Preview image
  useEffect(() => {
    if (Photo) {
      const fileExtension = Photo.name.split(".").pop().toLowerCase();

      const imageExtensions = ["jpg", "jpeg", "png", "webp"];

      if (imageExtensions.includes(fileExtension)) {
        const reader = new FileReader();
        reader.onload = () => {
          setPreviewPhoto(reader.result);
        };
        reader.readAsDataURL(Photo);
      } else {
        showToast(
          "Only Image of type " + imageExtensions.join(",") + " are Allowed",
          "error",
          2500
        );
        setPreviewPhoto(null);
        refs.Photo.current.removeFiles();
      }
    } else {
      setPreviewPhoto(null);
    }
    // eslint-disable-next-line
  }, [Photo]);

  //onchange handler for inputs
  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  function ValidateControls() {
    if (values.title === "") {
      refs.title.current.focus();
      showToast("Please Enter Title", "warning", 1500);
      return false;
    } else if (values.description === "") {
      refs.description.current.focus();
      showToast("Please Enter Description", "warning", 1500);
      return false;
    } else if (values.articleLink === "") {
      refs.articleLink.current.focus();
      showToast("Please Enter Article Link", "warning", 1500);
      return false;
    } else if (!isLinkValid) {
      refs.articleLink.current.focus();
      showToast("Please Enter a Valid Article Link", "warning", 1500);
      return false;
    } else if (!Photo && !previewPhoto) {
      refs.Photo.current.fileInput.focus();
      showToast("Please Select a Image to upload", "warning", 1500);
      return false;
    }
    return true;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (ValidateControls()) {
      try {
        startLoading();

        const formData = new FormData();
        const config = {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: "Bearer " + authtoken,
          },
        };

        formData.append("Photo", Photo);
        formData.append("Description", values.description);
        formData.append("ArticleLink", values.articleLink);
        formData.append("Title", values.title);

        var response = await axios.post(
          `${server}api/Article`,
          formData,
          config
        );
        if (response.status === 200) {
          showToast("Record Added", "success");
          setIsAdding(false);
        }
        stopLoading();
      } catch (error) {
        showToast(error.toString(), "error", 15000);
        console.error(error);
        stopLoading();
      }
    }
  };

  const checkLinkValidity = (url) => {
    if (isValidUrl(url)) {
      setIsLinkValid(true);
      return;
    }
    setIsLinkValid(false);
  };

  useEffect(() => {
    checkLinkValidity(values.articleLink);
  }, [values.articleLink]);
  

  function isValidUrl(url) {
    const regex = /^(http|https):\/\/\w+\.\w+/;
    return regex.test(url);
  }

  return (
    <EuiForm component="form" onSubmit={handleSubmit}>
      <div style={{ padding: "5px" }}>
        {
          //renders controls
          inputs.map((input) => (
            <FormInput
              key={input.id}
              {...input}
              value={values[input.name]}
              onChange={onChange}
            />
          ))
        }
        <FormInput
          id="articleLink"
          name="articleLink"
          type="text"
          placeholder="Enter Article Link"
          label="Article Link"
          inputRef={refs.articleLink}
          key="articleLink"
          value={values.articleLink}
          onChange={onChange}
          onBlur={(e) => checkLinkValidity(e.target.value)}
          prepend={
            <div style={{ padding: "9px 5px 5px 5px" }}>
              <EuiIcon
                type="link"
                color={isLinkValid ? COLORS[3] : COLORS[5]}
              />
              {isLinkValid ? "Valid URL" : "Invalid URL"}
            </div>
          }
        />
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiFormRow label="Article Photo">
              <EuiFilePicker
                onChange={(files) => setPhoto(files[0])}
                ref={refs.Photo}
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem grow={false} style={{ padding: "20px" }}>
            <EuiFormRow>
              <EuiImage
                alt="Preview Photo"
                src={previewPhoto}
                size="m"
                allowFullScreen
                style={{ display: previewPhoto === null ? "none" : "block" }}
              />
            </EuiFormRow>
          </EuiFlexItem>
        </EuiFlexGroup>
        <br />
        <div
          style={{
            display: "flex",
            justifyContent: "start",
            gap: "20px",
            alignItems: "center",
          }}
        >
          <EuiButton size="m" type="submit" fill>
            Save
          </EuiButton>
          <EuiButton
            size="m"
            type="button"
            onClick={() => setIsAdding(false)}
            color="warning"
            fill
          >
            Cancel
          </EuiButton>
        </div>
      </div>
    </EuiForm>
  );
};

export default Add;

import { EuiConfirmModal } from '@elastic/eui';
import React, { useState } from 'react'

export var showDialog;
export var hideDialog;

function ConfirmModel({ title, text, confirmButton, cancelButton, confirmFunction }) {
    const [isDestroyModalVisible, setIsDestroyModalVisible] = useState(false);
    const [record, setrecord] = useState({});
    
    hideDialog = () => setIsDestroyModalVisible(false);
    showDialog = (precord) => {
        setrecord(precord);
        setIsDestroyModalVisible(true);
    }

    let destroyModal;

    if (isDestroyModalVisible) {
        destroyModal = (
            <EuiConfirmModal
                title={title}
                onCancel={hideDialog}
                onConfirm={()=>confirmFunction(record)}
                cancelButtonText={cancelButton}
                confirmButtonText={confirmButton}
                buttonColor="danger"
                defaultFocusedButton="confirm"
            >
                <p>{text}</p>
            </EuiConfirmModal>
        );
    }

    return (
        <div>
            {destroyModal}
        </div>
    )
}

export default ConfirmModel

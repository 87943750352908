import React, { useEffect, useRef, useState } from 'react';
import FormInput from '../../Components/FormInput';
import { showToast } from '../../Components/Toast';
import { EuiButton, EuiDatePicker, EuiFilePicker, EuiFlexGroup, EuiFlexItem, EuiForm, EuiFormRow, EuiImage } from '@elastic/eui';
import axios from '../../axiosService';

const Edit = ({ server, authtoken, EditData, startLoading, stopLoading, setIsEditing }) => {
  const [values, setValues] = useState(EditData);
  const [Photo, setPhoto] = useState(null);
  const [previewPhoto, setPreviewPhoto] = useState(null);
  const imgUrl = server + EditData.photo;

  const refs = {
    committeeMemberName: useRef(),
    address: useRef(),
    mobileNo: useRef(),
    emailId: useRef(),
    designation: useRef(),
    description: useRef(),
    dob: useRef(),
    dateOfJoining: useRef(),
    Photo: useRef()
  }

  const inputs = [
    {
      id: "committeeMemberName",
      name: "committeeMemberName",
      type: "text",
      placeholder: "Enter Committee Member Name",
      label: "Name"
    },
    {
      id: "address",
      name: "address",
      type: "text",
      placeholder: "Enter Address",
      label: "Address"
    },
    {
      id: "mobileNo",
      name: "mobileNo",
      type: "text",
      placeholder: "Enter Mobile No",
      maxlength: 10,
      label: "mobileNo"
    },
    {
      id: "emailId",
      name: "emailId",
      type: "text",
      placeholder: "Enter Email ID",
      label: "Email ID"
    },
    {
      id: "designation",
      name: "designation",
      type: "text",
      placeholder: "Enter Designation",
      label: "Designation"
    },
    {
      id: "description",
      name: "description",
      type: "text",
      placeholder: "Enter Description",
      label: "Description"
    },
  ];

  //Preview image
  useEffect(() => {
    if (Photo) {
      const fileExtension = Photo.name.split('.').pop().toLowerCase();

      const imageExtensions = ['jpg', 'jpeg', 'png', 'webp'];

      if (imageExtensions.includes(fileExtension)) {
        const reader = new FileReader();
        reader.onload = () => {
          setPreviewPhoto(reader.result);
        };
        reader.readAsDataURL(Photo);
      } else {
        showToast('Only Image of type ' + imageExtensions.join(",") + ' are Allowed', "error", 2500);
        setPreviewPhoto(imgUrl);
        refs.Photo.current.removeFiles();
      }
    } else {
      setPreviewPhoto(imgUrl);
    }
  }, [Photo])

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onDOBChange = (date) => {
    setValues({ ...values, dob: date });
  };

  const ondateOfJoiningChange = (date) => {
    setValues({ ...values, dateOfJoining: date });
  };

  function ValidateControls() {
    if (values.committeeMemberName === "") {
      refs.committeeMemberName.current.focus();
      showToast('Please Enter Name', "warning", 1500);
      return false;
    } else if (values.address === "") {
      refs.address.current.focus()
      showToast('Please Enter Date', "warning", 1500);
      return false;
    } else if (values.mobileNo === "") {
      refs.mobileNo.current.focus()
      showToast('Please Enter Mobile No', "warning", 1500);
      return false;
    } else if (values.emailId === "") {
      refs.emailId.current.focus()
      showToast('Please Enter Email ID', "warning", 1500);
      return false;
    } else if (values.designation === "") {
      refs.designation.current.focus()
      showToast('Please Enter Designation', "warning", 1500);
      return false;
    } else if (values.description === "") {
      refs.description.current.focus()
      showToast('Please Enter Description', "warning", 1500);
      return false;
    } else if (values.dob === null) {
      refs.dob.current.setFocus()
      showToast('Please Select DOB', "warning", 1500);
      return false;
    } else if (values.dateOfJoining === null) {
      refs.dateOfJoining.current.setFocus()
      showToast('Please Select Date Of Joining', "warning", 1500);
      return false;
    } else if (!Photo && !previewPhoto) {
      refs.Photo.current.fileInput.focus();
      showToast('Please Select a Image to upload', "warning", 1500);
      return false;
    }
    return true;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (ValidateControls()) {
      try {
        startLoading();

        const formData = new FormData();
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
            'Authorization': "Bearer " + authtoken,
          }
        };

        formData.append('CommitteeMemberId', values.committeeMemberId);
        formData.append('CommitteeMemberName', values.committeeMemberName);
        formData.append('Address', values.address);
        formData.append('MobileNo', values.mobileNo);
        formData.append('EmailId', values.emailId);
        formData.append('Designation', values.designation);
        formData.append('Description', values.description);
        formData.append('Dob', values.dob.format());
        formData.append('DateOfJoining', values.dateOfJoining.format());
        if (Photo)
          formData.append('Photo', Photo);

        const response = await axios.put(`${server}api/Committee/` + values.committeeMemberId, formData, config);

        if (response.status === 200) {
          showToast("Record Updated", "success", 2000);
          setIsEditing(false);
        }
        stopLoading();

      } catch (error) {
        showToast(error.toString(), "error", 15000);
        console.error(error);
        stopLoading();
      }
    }
  };

  return (
    <EuiForm component='form' onSubmit={handleSubmit} >
      <div style={{ padding: "5px" }}>
        {//renders controls
          inputs.map((input) => (
            <FormInput
              key={input.id}
              {...input}
              value={values[input.name]}
              onChange={onChange}
              inputRef={refs[input.name]}
            />
          ))}
        <EuiFormRow label="DOB" fullWidth>
          <EuiDatePicker
            id="dob"
            name="dob"
            selected={values.dob}
            onChange={onDOBChange}
            placeholder='Please Select DOB'
            inputRef={refs.dob}
            utcOffset="00:00"
            fullWidth
          />
        </EuiFormRow>
        <EuiFormRow label="Date Of Joining" fullWidth>
          <EuiDatePicker
            id="dateOfJoining"
            name="dateOfJoining"
            selected={values.dateOfJoining}
            onChange={ondateOfJoiningChange}
            placeholder='Please Select Date Of Joining'
            inputRef={refs.dateOfJoining}
            utcOffset="00:00"
            fullWidth
          />
        </EuiFormRow>
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiFormRow label="Photo">
              <EuiFilePicker
                onChange={(files) => setPhoto(files[0])}
                ref={refs.Photo}
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem grow={false} style={{ padding: "20px" }}>
            <EuiFormRow>
              <EuiImage alt='Preview Photo' src={previewPhoto} size="m" allowFullScreen style={{ display: previewPhoto === null ? "none" : "block" }} />
            </EuiFormRow>
          </EuiFlexItem>
        </EuiFlexGroup>
        <br />
        <div style={{ display: 'flex', justifyContent: "start", gap: "20px", alignItems: "center" }}>
          <EuiButton size='m' type='submit' fill>Save</EuiButton>
          <EuiButton size='m' type='button' onClick={() => setIsEditing(false)} color='warning' fill>Cancel</EuiButton>
        </div>
      </div>
    </EuiForm>
  );
};

export default Edit;

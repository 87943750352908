import React, { useEffect, useRef, useState } from 'react';
import { showToast } from '../../Components/Toast';
import { EuiButton, EuiButtonIcon, EuiFilePicker, EuiFlexGroup, EuiFlexItem, EuiForm, EuiFormRow, EuiImage } from '@elastic/eui';
import axios from '../../axiosService';

const Add = ({ server, authtoken, startLoading, stopLoading, setIsAdding, causeId }) => {
  const [Photos, setPhotos] = useState([])
  const [PreviewImages, setPreviewImages] = useState([])
  const refs = {
    Photos: useRef()
  }

  const handleFileChange = (files) => {
    startLoading();
    const allowedFiles = ["webp", "jpeg", "jpg", "png"];
    const filteredFiles = [];

    var ItemCount = 0;
    files.forEach((file) => {
      if (ItemCount < 10) {
        if (file) {
          const ext = file.name.split(".").pop().toLowerCase();
          if (allowedFiles.includes(ext)) {
            filteredFiles.push(file);
            ItemCount++;
          }
        }
      } else {
        return;
      }
    });

    setPhotos(filteredFiles);
  };

  const RemoveImage = (index) => {
    const updatedImageFiles = [...Photos];
    updatedImageFiles.splice(index, 1);
    setPhotos(updatedImageFiles);
  }

  useEffect(() => {
    if (Photos.length > 0) {
      var promises = [];

      Photos.forEach(file => {
        if (file) {
          const reader = new FileReader();
          const promise = new Promise((resolve) => {
            reader.onload = () => {
              resolve(reader.result);
            };
          });
          reader.readAsDataURL(file);
          promises.push(promise);
        }
      });

      Promise.all(promises)
        .then(results => {
          setPreviewImages(results);
          stopLoading();
        })
        .catch(error => {
          console.error("Error reading files:", error);
          stopLoading();
        });
    } else {
      setPreviewImages([]);
      stopLoading();
    }
  }, [Photos]);

  const uploadFile = async (file, index) => {
    try {
      const formData = new FormData();
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          'Authorization': "Bearer " + authtoken,
        }
      };

      formData.append('CauseId', causeId);
      formData.append('Photo', file);

      const response = await axios.post(`${server}api/CausePhotos`, formData, config);

      if (response.status === 200) {
        console.log(await response.data);
      }

    } catch (error) {
      showToast(error.toString(), "error", 15000);
      console.error(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    startLoading();
    const UploadPromises = Photos.map((item, index) => {
      return uploadFile(item, index).then(() => {

      })
    });

    Promise.all(UploadPromises).then(() => {
      refs.Photos.current.removeFiles();
      stopLoading();
      setIsAdding(false);
      showToast("Images uploaded", "success")
    }).catch(error => {
      console.error(error);
    })
  }

  return (
    <EuiForm component='form' onSubmit={handleSubmit} >
      <EuiFormRow label="Cause Photos" fullWidth helpText="Upload upto 10 files at a time. Maximum Image Size : 10MB ">
        <EuiFilePicker
          onChange={(files) => handleFileChange(Array.from(files))}
          ref={refs.Photos}
          fullWidth
          multiple={true}
        />
      </EuiFormRow>
      <EuiFormRow label="Preview" fullWidth helpText="You can remove a file here before uploading">
        <EuiFlexGroup style={{ overflow: "scroll", maxHeight: "310px" }} className="eui-yScroll ">
          {
            PreviewImages.map((image, index) => {
              return (
                <EuiFlexItem grow={false} style={{ height: '284px', flexShrink: 0, width: "auto" }}>
                  {/* <EuiImage alt='Preview Image' src={image} style={{ maxHeight: '260px', height: "260px", width: "100%" }} allowFullScreen />
                  <EuiButtonIcon iconType="trash" display="fill" color='danger' onClick={() => { }} style={{zIndex: "10000" }} /> */}
                  <div style={{ position: "relative", flex: 1 }}>
                    <EuiImage alt='Preview Image' src={image} style={{ maxHeight: '284px', height: "100%", width: "100%" }} allowFullScreen />
                  </div>
                  <div style={{ position: "relative", flex: 0 }}>
                    <EuiButtonIcon
                      iconType="trash"
                      display="fill"
                      color='danger'
                      onClick={() => RemoveImage(index)}
                      style={{ position: "absolute", bottom: 0, right: 0, height: "35px", width: "35px", zIndex: "150" }}
                    />
                  </div>
                </EuiFlexItem>
              )
            })
          }
        </EuiFlexGroup>
      </EuiFormRow>
      <div style={{ display: 'flex', justifyContent: "start", gap: "20px", alignItems: "center" }}>
        <EuiButton size='m' type='submit' fill>Save</EuiButton>
        <EuiButton size='m' type='button' onClick={() => setIsAdding(false)} color='warning' fill>Cancel</EuiButton>
      </div>
    </EuiForm>
  );
};

export default Add;

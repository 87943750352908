import { EuiBadge, EuiPageBody } from '@elastic/eui'
import React, { useCallback, useEffect, useState } from 'react'
import axios from '../axiosService';
import Loader from '../Components/Loader';
import Toast, { showToast } from '../Components/Toast';
import Table from '../Components/Table';

function PaymentDetails() {
  const server = process.env.REACT_APP_APIPATH
  const authtoken = localStorage.getItem("authtoken");
  const config = {
    headers: {
      'Authorization': "Bearer " + authtoken
    }
  }
  const [IsLoading, setIsLoading] = useState(false)
  const startLoading = useCallback(() => {
    setIsLoading(true);
  }, []);

  const stopLoading = useCallback(() => {
    setIsLoading(false);
  }, []);

  const [Data, setData] = useState([])

  function convertDateToIndianFormat(utcTimestamp) {
    const date = new Date(utcTimestamp);

    // Calculate the offset in minutes
    const offsetInMinutes = date.getTimezoneOffset();

    // Adjust for IST by subtracting the offset from the Date object
    const istDateTime = new Date(date.getTime() - offsetInMinutes * 60000);

    // Format the IST datetime
    const formattedISTDateTime = istDateTime.toLocaleString("en-IN", {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      timeZone: "Asia/Kolkata" // Ensure IST for formatting
    }).replace(',', '').toUpperCase();;

    return formattedISTDateTime;
  }

  const colorStatusMapping = {
    'success': 'primary',
    'pending': 'warning',
    'failure': 'danger'
  }

  const columns = [
    { field: 'rowNumber', name: 'Row Number', sortable: true },
    { field: 'txnID', name: 'Transaction ID', sortable: true },
    {
      field: 'amount', name: 'Amount', sortable: true, render: (amount) => (
        <span>₹{amount}</span>
      )
    },
    {
      field: 'status', name: 'Status', sortable: true, render: (status) => (
        <EuiBadge color={colorStatusMapping[status]}>{status.toUpperCase()}</EuiBadge>
      )
    },
    { field: 'firstName', name: 'First Name', sortable: true },
    { field: 'lastName', name: 'Last Name', sortable: true },
    { field: 'emailId', name: 'Email ID', sortable: false },
    { field: 'mobileNo', name: 'Mobile No', sortable: false },
    {
      field: 'date', name: 'Date', sortable: true, render: (date) => (
        <span>{convertDateToIndianFormat(date)}</span>
      )
    },
    { field: 'pan', name: 'PAN', sortable: false }
  ]

  const filldata = async () => {
    try {
      startLoading();
      const response = await axios.get(`${server}api/Payment/GetAllPayment`, config);
      if (response.status === 200) {
        const data = await response.data;
        setData(data);
      }
      stopLoading();
    } catch (error) {
      showToast(error.toString(), "error", 15000);
      stopLoading();
    }
  }

  useEffect(() => {
    filldata();
    // eslint-disable-next-line
  }, [])

  return (
    <EuiPageBody style={{ width: "100%", padding: "10px" }}>
      <Loader IsLoading={IsLoading} />
      <header>
        <h1 style={{ fontSize: "20px" }}>Donations</h1>
      </header>
      <Table data={Data} columns={columns} height={80} />
      <Toast />
    </EuiPageBody>
  )
}

export default PaymentDetails

import React, { useEffect, useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { EuiButton, EuiSkeletonRectangle } from "@elastic/eui";
import axios from "../../axiosService";
import { useNavigate, useParams } from "react-router-dom";
import Toast, { showToast } from "../../Components/Toast";
import Loader from "../../Components/Loader";

function TinyMceEditor() {
  const server = process.env.REACT_APP_APIPATH;
  const authtoken = localStorage.getItem("authtoken");
  const editorRef = useRef(null);
  const params = useParams();
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);
  const [data, setData] = useState("");
  const config = {
    headers: {
      "content-type": "application/json",
      Authorization: "Bearer " + authtoken,
    },
  };

  function addClassToH1(htmlString) {
    // Create a new DOM parser
    const parser = new DOMParser();
    
    // Parse the HTML string into a document
    const doc = parser.parseFromString(htmlString, 'text/html');
    
    // Get all h1 elements
    const h1Elements = doc.querySelectorAll('h1');
    
    // Add the "heading-main" class to each h1 element
    h1Elements.forEach(h1 => h1.classList.add('heading-main'));
    
    // Serialize the document back into an HTML string
    const serializer = new XMLSerializer();
    const updatedHtmlString = serializer.serializeToString(doc);
    
    return updatedHtmlString;
  }

  const save = async () => {
    if (editorRef.current) {
      var content = editorRef.current.getContent();
      const escapedContent = addClassToH1(escapeHtml(content));

      const response = await axios.put(
        `${server}api/Cause/${params.id}/page`,
        { pageHtml: escapedContent },
        config
      );

      if (response.status === 200) {
        showToast("Page Updated", "success", 2000);
        navigate("/causes")
      }
    }
  };

  function escapeHtml(html) {
    return html.replace(/"/g, '"');
  }

  const filldata = async () => {
    try {
      setisLoading(true);
      const response = await axios.get(`${server}api/Cause/${params.id}/page`, config);
      if (response.status === 200) {
        const data = await response.data;
        setData(data);
      }
    } catch (error) {
      showToast(error.toString(), "error", 15000);
    }finally{
      setisLoading(false);
    }
  }

  useEffect(() => {
    filldata();
    // eslint-disable-next-line
  }, [])

  useEffect(()=>{
    if (editorRef.current) {
      editorRef.current.setContent(data,{format:"html"});
    }
  },[data])

  return (
    <div>
      <EuiSkeletonRectangle isLoading={isLoading} width="100%" height="400px">
      <Editor
        tinymceScriptSrc="/tinymce/tinymce.min.js"
        onInit={(evt, editor) => (editorRef.current = editor)}
        initialValue={data}
        licenseKey="gpl"
        init={{
          height: 500,
          quickbars_selection_toolbar:
            "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
          menubar: "file edit view insert format tools table help",
          plugins:
            "preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons accordion",
          toolbar:
            "undo redo | accordion accordionremove | blocks fontfamily fontsize | bold italic underline strikethrough | align numlist bullist | link image | table media | lineheight outdent indent| forecolor backcolor removeformat | charmap emoticons | code fullscreen preview | save print | pagebreak anchor codesample | ltr rtl",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        }}
      />
      <br />
      <EuiButton fill
        onClick={() => {
          save();
        }}
      >
        Save
      </EuiButton>{" "}
      &nbsp;&nbsp;
      <EuiButton color="warning" fill
        onClick={() => navigate("/causes")}
      >
        Cancel
      </EuiButton>
      <Toast /></EuiSkeletonRectangle>
    </div>
  );
}

export default TinyMceEditor;

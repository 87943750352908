import { EuiButton, EuiForm, EuiPageBody } from '@elastic/eui'
import React, { useCallback, useRef, useState } from 'react'
import Toast, { showToast } from '../Components/Toast';
import axios from '../axiosService';
import Loader from '../Components/Loader';
import FormInput from '../Components/FormInput';
import { useNavigate } from 'react-router-dom';

function ChangePassword({ isAuthenticated, setIsAuthenticated }) {
    const server = process.env.REACT_APP_APIPATH
    const authtoken = localStorage.getItem("authtoken");
    const config = {
        headers: {
            'Authorization': "Bearer " + authtoken
        }
    }
    const navigate = useNavigate();

    const [IsLoading, setIsLoading] = useState(false)
    const startLoading = useCallback(() => {
        setIsLoading(true);
    }, []);

    const stopLoading = useCallback(() => {
        setIsLoading(false);
    }, []);

    const [values, setValues] = useState({
        oldPassword: "",
        newPassword: "",
        confirmPassword: ""
    });

    const refs = {
        oldPassword: useRef(),
        newPassword: useRef(),
        confirmPassword: useRef()
    }

    const inputs = [
        {
            id: "oldPassword",
            name: "oldPassword",
            type: "text",
            placeholder: "Enter Old Password",
            label: "Old Password"
        },
        {
            id: "newPassword",
            name: "newPassword",
            type: "text",
            placeholder: "Enter New Password",
            label: "New Password"
        },
        {
            id: "confirmPassword",
            name: "confirmPassword",
            type: "text",
            placeholder: "Enter New Password Again",
            label: "Confim New Password"
        }
    ]

    const onChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    };

    function ValidateControls() {
        if (values.oldPassword === "") {
            refs.oldPassword.current.focus();
            showToast('Please Enter Old Password', "warning", 1500);
            return false;
        } else if (values.newPassword === "") {
            refs.address.current.focus()
            showToast('Please Enter New Password', "warning", 1500);
            return false;
        } else if (values.confirmPassword === "") {
            refs.confirmPassword.current.focus()
            showToast('Please Enter New Password Again', "warning", 1500);
            return false;
        } else if (values.confirmPassword != values.newPassword) {
            refs.confirmPassword.current.focus()
            showToast('New Password and Confirm New Password Do not Match!', "warning", 1500);
            return false;
        }
        return true;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (ValidateControls()) {
            startLoading();

            axios.post(`${server}api/AdminAuthentication/ChangePassword`, values, config)
                .then(response => {
                    const data = response.data;
                    if (response.status === 200) {
                        showToast(data.message, "success", 2000);
                        localStorage.removeItem("authtoken");
                        setIsAuthenticated(false);
                        navigate(`login`);
                    }
                    stopLoading();
                }).catch(error => {
                    const data = error.response.data;
                    showToast(data, "error", 2000);
                    stopLoading();
                });
        };
    };

    return (
        <EuiPageBody style={{ width: "100%", padding: "10px" }}>
            <Loader IsLoading={IsLoading} />
            <Toast />
            <header>
                <h1 style={{ fontSize: "20px" }}>Change Password</h1>
            </header>
            <EuiForm component='form' onSubmit={handleSubmit} >
                {//renders controls
                    inputs.map((input) => (
                        <FormInput
                            key={input.id}
                            {...input}
                            value={values[input.name]}
                            onChange={onChange}
                            inputRef={refs[input.name]}
                        />
                    ))}
                <br />
                <div style={{ display: 'flex', justifyContent: "start", gap: "20px", alignItems: "center" }}>
                    <EuiButton size='m' type='submit' fill>Save</EuiButton>
                    <EuiButton size='m' type='button' color='warning' onClick={()=> navigate("/")} fill>Cancel</EuiButton>
                </div>
            </EuiForm>
        </EuiPageBody>
    )
}

export default ChangePassword

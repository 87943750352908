import React, { useCallback, useEffect, useState } from 'react'
import Toast, { showToast } from '../../Components/Toast';
import Header from './Header';
import Add from './Add';
import Edit from './Edit';
import Loader from '../../Components/Loader';
import Table from '../../Components/Table';
import { EuiImage, EuiLink, EuiPageBody } from '@elastic/eui';
import axios from '../../axiosService';
import ConfirmModel, { showDialog, hideDialog } from '../../Components/ConfirmModel'

function Articles() {
  const server = process.env.REACT_APP_APIPATH
  const authtoken = localStorage.getItem("authtoken");
  const config = {
    headers: {
      'Authorization': "Bearer " + authtoken
    }
  }
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [EditData, setEditData] = useState({});

  const [IsLoading, setIsLoading] = useState(false)
  const startLoading = useCallback(() => {
    setIsLoading(true);
  }, []);

  const stopLoading = useCallback(() => {
    setIsLoading(false);
  }, []);

  const [Data, setData] = useState([])

  const columns = [
    { field: 'rowNumber', name: 'Row Number', sortable: true },
    { field: 'title', name: 'Title', sortable: true },
    { field: 'description', name: 'Description', sortable: true },
    { field: 'articleLink', name: 'Article Link', render: (articleLink) => (
      <EuiLink href={articleLink} target='_blank' >{articleLink}</EuiLink>
    ) , sortable: false },
    {
      field: 'photo', name: 'Photo', render: (Photo) => (
        <EuiImage size="m" src={`${server}${Photo}`} alt='No Image Found' allowFullScreen hasShadow />
      )
    }
  ]

  // delete function
  const deleteRecord = async (record) => {
    try {
      startLoading();
      var response = await axios.delete(`${server}api/Article/${record.id}`, config)
      if (response.status === 200) {
        showToast('Record Deleted', "success");
        filldata();
      }
      hideDialog();
      stopLoading();
    } catch (error) {
      showToast(error.toString(), "error", 15000);
      console.error(error);
      stopLoading();
    }
  };

  // edit function
  const editRecord = async (record) => {
    try {
      startLoading();
      var response = await axios.get(`${server}api/Article/${record.id}`, config);
      if (response.status === 200) {
        const data = await response.data;
        setEditData(data);
        setIsEditing(true);
      }
      stopLoading();
    } catch (error) {
      showToast(error.toString(), "error", 15000);
      console.error(error);
      stopLoading();
    }
  };

  //declare edit and delete buttons
  const actions = [
    {
      name: 'Edit',
      isPrimary: true,
      description: 'Edit this Record',
      icon: 'pencil',
      type: 'icon',
      onClick: editRecord,
      'data-test-subj': 'action-edit',
    }, {
      name: "Delete",
      description: 'Delete this Record',
      icon: 'trash',
      color: 'danger',
      type: 'icon',
      onClick: showDialog,
      isPrimary: true,
      'data-test-subj': 'action-delete',
    }
  ];

  //add buttons to columns
  const columnsWithActions = [
    ...columns,
    {
      name: 'Actions',
      actions,
    },
  ];

  //get table data
  async function filldata() {
    try {
      startLoading();
      var response = await axios.get(`${server}api/Article`, config);
      if (response.status === 200) {
        var data = response.data;
        setData(data);
      }
      stopLoading();
    } catch (error) {
      showToast(error.toString(), "error", 15000);
      stopLoading();
    }

  }

  useEffect(() => {
    filldata();
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!isEditing && !isAdding) {
      filldata()
    }
    // eslint-disable-next-line
  }, [isAdding, isEditing]);

  useEffect(() => {
    if (!isEditing) {
      setEditData({});
    }
  }, [isEditing]);

  return (
    <EuiPageBody style={{ width: "100%", padding: "10px" }}>
      <Loader IsLoading={IsLoading} />
      {!isAdding && !isEditing && (
        <>
          <Header
            headerText="Article Master"
            setIsAdding={setIsAdding}
          />
          <Table data={Data} columns={columnsWithActions} height={70} />
        </>
      )}
      {isAdding && (
        <Add
          server={server}
          authtoken={authtoken}
          startLoading={startLoading}
          stopLoading={stopLoading}
          setIsAdding={setIsAdding}
        />
      )}
      {isEditing && (
        <Edit
          server={server}
          authtoken={authtoken}
          EditData={EditData}
          startLoading={startLoading}
          stopLoading={stopLoading}
          setIsEditing={setIsEditing}
        />
      )}
      <Toast />
      <ConfirmModel title="Delete Record ?" text="The Record will be Deleted. Please Proceed With Caution !" confirmButton="Delete" cancelButton="Cancel" confirmFunction={deleteRecord} />
    </EuiPageBody>
  )
}

export default Articles

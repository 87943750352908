import axios from 'axios';

const instance = axios.create();
// Add interceptors
instance.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      console.error('Unauthorized request. Redirecting to login page.');
      localStorage.removeItem("authtoken");
      localStorage.removeItem("role");
      localStorage.setItem("is_authenticated", false);
      window.location.href ='/login';
    } else if (error.response && error.response.status === 403) {
      console.error('Unauthorized request. Redirecting to Dashboard');
      window.location.href ='/';
    }
    return Promise.reject(error);
  }
);

export default instance;
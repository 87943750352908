import React, { useEffect, useRef, useState } from 'react';
import FormInput from '../../Components/FormInput';
import { showToast } from '../../Components/Toast';
import { EuiButton, EuiFilePicker, EuiFlexGroup, EuiFlexItem, EuiForm, EuiFormRow, EuiImage, EuiSwitch } from '@elastic/eui';
import axios from '../../axiosService';

const Add = ({ server, authtoken, startLoading, stopLoading, setIsAdding }) => {
  const [values, setValues] = useState({
    OrderNumber: "",
    IsHidden: false
  });
  const [Photo, setPhoto] = useState(null)
  const [previewPhoto, setPreviewPhoto] = useState(null)

  const refs = {
    OrderNumber: useRef(),
    IsHidden: useRef(),
    Photo: useRef()
  }

  const inputs = [
    {
      id: "OrderNumber",
      name: "OrderNumber",
      type: "number",
      placeholder: "Enter Order Number",
      label: "Order Number",
      min: 1
    }
  ];

  useEffect(() => {
    if (Photo) {
      const fileExtension = Photo.name.split('.').pop().toLowerCase();

      const imageExtensions = ['jpg', 'jpeg', 'png', 'webp'];

      if (imageExtensions.includes(fileExtension)) {
        const reader = new FileReader();
        reader.onload = () => {
          setPreviewPhoto(reader.result);
        };
        reader.readAsDataURL(Photo);
      } else {
        showToast('Only Image of type ' + imageExtensions.join(",") + ' are Allowed', "error", 2500);
        setPreviewPhoto(null);
        refs.Photo.current.removeFiles();
      }
    } else {
      setPreviewPhoto(null);
    }
  }, [Photo])

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onCheckedChange = (e) => {
    setValues({ ...values, IsHidden: !e.target.checked });
  };

  function ValidateControls() {
    if (values.OrderNumber === "" || isNaN(values.OrderNumber)) {
      refs.OrderNumber.current.focus();
      showToast('Please Enter Order Number', "warning", 1500);
      return false;
    } else if (!Photo && !previewPhoto) {
      refs.Photo.current.fileInput.focus();
      showToast('Please Select a Image to upload', "warning", 1500);
      return false;
    }
    return true;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (ValidateControls()) {
      try {
        startLoading();
        const formData = new FormData();
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
            'Authorization': "Bearer " + authtoken
          },
        };

        formData.append('OrderNumber', values.OrderNumber);
        formData.append('IsHidden', values.IsHidden);
        formData.append('Photo', Photo);

        const response = await axios.post(`${server}api/HomeSlider`, formData, config);
        if (response.status === 200) {
          showToast("New Record Added", "success", 2000);
          setIsAdding(false);
        }
        stopLoading();
      } catch (error) {
        showToast(error.toString(), "error", 15000);
        console.error(error);
        stopLoading();
      }
    }
  };

  return (
    <EuiForm component='form' onSubmit={handleSubmit} >
      <div style={{ padding: "5px" }}>
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiFormRow label="Photo">
              <EuiFilePicker
                onChange={(files) => setPhoto(files[0])}
                ref={refs.Photo}
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem grow={false} style={{ padding: "20px" }}>
            <EuiFormRow>
              <EuiImage alt='Preview Photo' src={previewPhoto} size="l" allowFullScreen style={{ display: previewPhoto === null ? "none" : "block" }} />
            </EuiFormRow>
          </EuiFlexItem>
        </EuiFlexGroup>
        {//renders controls
          inputs.map((input) => (
            <FormInput
              key={input.id}
              {...input}
              value={values[input.name]}
              inputRef={refs[input.name]}
              onChange={onChange}
            />
          ))}
        <EuiFormRow label="Is Visible">
          <EuiSwitch
            label={!values.IsHidden ? 'Visible' : 'Hidden'}
            checked={!values.IsHidden}
            onChange={onCheckedChange}
          />
        </EuiFormRow>
        <br />
        <div style={{ display: 'flex', justifyContent: "start", gap: "20px", alignItems: "center" }}>
          <EuiButton size='m' type='submit' fill>Save</EuiButton>
          <EuiButton size='m' type='button' onClick={() => setIsAdding(false)} color='warning' fill>Cancel</EuiButton>
        </div>
      </div>
    </EuiForm>
  );
};

export default Add;

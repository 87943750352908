import { EuiButton, EuiHeader, EuiHeaderLinks, EuiHeaderSection, EuiHeaderSectionItem, EuiHeaderSectionItemButton, EuiIcon, EuiImage } from '@elastic/eui'
import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom';

function Header({ isAuthenticated, setIsAuthenticated, navIsOpen, setNavIsOpen }) {
    const navigate = useNavigate();

    const LogOut = () => {
        localStorage.removeItem("authtoken");
        setIsAuthenticated(false);
        navigate(`login`);
    }

    const changePassword = () => {
        navigate(`change-password`);
    }

    return (
        <EuiHeader position="fixed">
            <EuiHeaderSection>
                <EuiHeaderSectionItem>
                    <EuiHeaderSectionItemButton
                        aria-label="Toggle main navigation"
                        onClick={() => setNavIsOpen(!navIsOpen)}
                    >
                        <EuiIcon type={'menu'} size="m" aria-hidden="true" />
                    </EuiHeaderSectionItemButton>
                    <EuiImage
                        src="../Assets/images/ROSHNI-LOGO.png"
                        style={{ blockSize: "30px", inlineSize: "100px" }}
                    />
                </EuiHeaderSectionItem>
            </EuiHeaderSection>

            <EuiHeaderLinks>
                {isAuthenticated && (
                    <EuiButton iconType="gear" onClick={changePassword}>Change Password</EuiButton>)}
                {isAuthenticated && (
                    <EuiButton iconType="push" onClick={LogOut}>Logout</EuiButton>)}
            </EuiHeaderLinks>
        </EuiHeader>
    )
}

export default Header

import React from 'react';
const Header = ({ headerText, setIsAdding }) => {
  return (
    <header>
      <h1 style={{fontSize:"20px"}}>{headerText}</h1>
      <div style={{ marginTop: '10px', marginBottom: '18px' }}>
      </div>
    </header>
  );
};

export default Header;

import React, { useRef, useState } from "react";
import FormInput from "../../Components/FormInput";
import { showToast } from "../../Components/Toast";
import {
  EuiButton,
  EuiForm,
} from "@elastic/eui";
import axios from "../../axiosService";

const Edit = ({
  server,
  authtoken,
  EditData,
  startLoading,
  stopLoading,
  setIsEditing,
}) => {
  const [values, setValues] = useState(EditData);

  const refs = {
    email: useRef()
  };

  const inputs = [
    {
      id: "email",
      name: "email",
      type: "text",
      placeholder: "Enter Email ID",
      label: "Email ID",
      inputRef: refs.email
    }
  ];

  //onchange handler for inputs
  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  function ValidateControls() {
    if (values.email === "") {
      refs.email.current.focus();
      showToast("Please Enter Email ID", "warning", 1500);
      return false;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.email)) {
      refs.email.current.focus();
      showToast("Please enter a valid email", "error", 1500);
      return false;
    }
    return true;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (ValidateControls()) {
      try {
        startLoading();

        const config = {
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + authtoken
          },
        };

        var response = await axios.put(
          `${server}api/ContactEmail/${values.contactEmailID}`,
          values,
          config
        );
        if (response.status === 200) {
          showToast("Record Updated", "success");
          setIsEditing(false);
        }
        stopLoading();
      } catch (error) {
        showToast(error.toString(), "error", 15000);
        console.error(error);
        stopLoading();
      }
    }
  };

  return (
    <EuiForm component="form" onSubmit={handleSubmit}>
      <div style={{ padding: "5px" }}>
        {
          //renders controls
          inputs.map((input) => (
            <FormInput
              key={input.id}
              {...input}
              value={values[input.name]}
              onChange={onChange}
            />
          ))
        }
        <br />
        <div
          style={{
            display: "flex",
            justifyContent: "start",
            gap: "20px",
            alignItems: "center",
          }}
        >
          <EuiButton size="m" type="submit" fill>
            Save
          </EuiButton>
          <EuiButton
            size="m"
            type="button"
            onClick={() => setIsEditing(false)}
            color="warning"
            fill
          >
            Cancel
          </EuiButton>
        </div>
      </div>
    </EuiForm>
  );
};

export default Edit;

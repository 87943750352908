import React from 'react'
import { EuiFieldNumber, EuiFieldText, EuiFieldPassword, EuiFormRow, EuiTextArea, EuiSelect } from '@elastic/eui';

function FormInput(props) {
    const { label, errorMessage, onChange, id, fullWidth = true, ...inputProps } = props;
    function renderInput() {
        switch (inputProps.type) {
            case "text":
                return (<EuiFormRow label={label} fullWidth={fullWidth}>
                    <EuiFieldText
                        {...inputProps}
                        onChange={onChange}
                        fullWidth={fullWidth}
                    />
                </EuiFormRow>);
            case "number":
                return (<EuiFormRow label={label} fullWidth={fullWidth}>
                    <EuiFieldNumber
                        {...inputProps}
                        onChange={onChange}
                        fullWidth={fullWidth}
                    />
                </EuiFormRow>);
            case "password":
                return (<EuiFormRow label={label} fullWidth={fullWidth}>
                    <EuiFieldPassword
                        {...inputProps}
                        onChange={onChange}
                        fullWidth={fullWidth}
                    />
                </EuiFormRow>);
            case "textarea":
                return (<EuiFormRow label={label} fullWidth={fullWidth}>
                    <EuiTextArea
                        {...inputProps}
                        onChange={onChange}
                        fullWidth={fullWidth}
                    />
                </EuiFormRow>);
            case "select":
                return (<EuiFormRow label={label} fullWidth={fullWidth}>
                    <EuiSelect
                        {...inputProps}
                        onChange={onChange}
                        fullWidth={fullWidth}
                    />
                </EuiFormRow>);
            default:
                break;
        }
    }

    return renderInput();
}

export default FormInput

import React, { useEffect, useRef, useState } from 'react';
import FormInput from '../../Components/FormInput';
import { showToast } from '../../Components/Toast';
import { EuiButton, EuiFilePicker, EuiFlexGroup, EuiFlexItem, EuiForm, EuiFormRow, EuiImage } from '@elastic/eui';
import axios from '../../axiosService';

const Edit = ({ server, authtoken, EditData, startLoading, stopLoading, setIsEditing }) => {
  const [values, setValues] = useState(EditData);
  const [Photo, setPhoto] = useState(null);
  const [previewPhoto, setPreviewPhoto] = useState(null);
  const imgUrl = server + EditData.photo;
  const refs = {
    causeName: useRef(),
    Photo: useRef()
  }

  const inputs = [
    {
      id: "causeName",
      name: "causeName",
      type: "text",
      placeholder: "Enter Cause Name",
      label: "Cause Name",
      inputRef: refs.causeName
    }
  ];

  //Preview image
  useEffect(() => {
    if (Photo) {
      const fileExtension = Photo.name.split('.').pop().toLowerCase();

      const imageExtensions = ['jpg', 'jpeg', 'png', 'webp'];

      if (imageExtensions.includes(fileExtension)) {
        const reader = new FileReader();
        reader.onload = () => {
          setPreviewPhoto(reader.result);
        };
        reader.readAsDataURL(Photo);
      } else {
        showToast('Only Image of type ' + imageExtensions.join(",") + ' are Allowed', "error", 2500);
        setPreviewPhoto(imgUrl);
        refs.Photo.current.removeFiles();
      }
    } else {
      setPreviewPhoto(imgUrl);
    }
  }, [Photo])

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  function ValidateControls() {
    if (values.causeName === "") {
      refs.causeName.current.focus();
      showToast('Please Enter Cause Name', "warning", 1500);
      return false;
    } else if (!Photo && !previewPhoto) {
      refs.Photo.current.fileInput.focus();
      showToast('Please Select a Image to upload', "warning", 1500);
      return false;
    }
    return true;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (ValidateControls()) {
      try {
        startLoading();

        const formData = new FormData();
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
            'Authorization': "Bearer " + authtoken,
          }
        };

        formData.append('CauseName', values.causeName);
        if (Photo)
          formData.append('Photo', Photo);

        const response = await axios.put(`${server}api/Cause/` + values.causeID, formData, config);

        if (response.status === 200) {
          showToast("Record Updated", "success", 2000);
          setIsEditing(false);
        }
        stopLoading();
      } catch (error) {
        showToast(error.toString(), "error", 15000);
        console.error(error);
        stopLoading();
      }
    }
  };

  return (
    <EuiForm component='form' onSubmit={handleSubmit} >
      <div style={{ padding: "5px" }}>
        {//renders controls
          inputs.map((input) => (
            <FormInput
              key={input.id}
              {...input}
              value={values[input.name]}
              onChange={onChange}
            />
          ))}
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiFormRow label="Cause Photo">
              <EuiFilePicker
                onChange={(files) => setPhoto(files[0])}
                ref={refs.Photo}
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem grow={false} style={{ padding: "20px" }}>
            <EuiFormRow>
              <EuiImage alt='Preview Photo' src={previewPhoto} size="l" allowFullScreen style={{ display: previewPhoto === null ? "none" : "block" }} />
            </EuiFormRow>
          </EuiFlexItem>
        </EuiFlexGroup>
        <br />
        <div style={{ display: 'flex', justifyContent: "start", gap: "20px", alignItems: "center" }}>
          <EuiButton size='m' type='submit' fill>Save</EuiButton>
          <EuiButton size='m' type='button' onClick={() => setIsEditing(false)} color='warning' fill>Cancel</EuiButton>
        </div>
      </div>
    </EuiForm>
  );
};

export default Edit;

import React, { useCallback, useEffect, useState } from 'react'
import Toast, { showToast } from '../../Components/Toast';
import Header from './Header';
import Add from './Add';
import Edit from './Edit';
import Loader from '../../Components/Loader';
import Table from '../../Components/Table';
import { EuiImage, EuiPageBody, EuiSwitch } from '@elastic/eui';
import axios from '../../axiosService';
import ConfirmModel, { showDialog, hideDialog } from '../../Components/ConfirmModel'

function Articles() {
  const server = process.env.REACT_APP_APIPATH
  const authtoken = localStorage.getItem("authtoken");
  const config = {
    headers: {
      'Authorization': "Bearer " + authtoken
    }
  }
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [EditData, setEditData] = useState({});

  const [IsLoading, setIsLoading] = useState(false)
  const startLoading = useCallback(() => {
    setIsLoading(true);
  }, []);

  const stopLoading = useCallback(() => {
    setIsLoading(false);
  }, []);

  const [Data, setData] = useState([])

  const columns = [
    { field: 'rowNumber', name: 'Row Number', sortable: true },
    {
      field: 'photo', name: 'Image', render: (imgUrl) => (
        <EuiImage size="m" src={server + imgUrl} alt='No Image Found' allowFullScreen hasShadow />
      )
    },
    { field: 'orderNumber', name: 'Order Number', sortable: true },
    {
      field: 'isHidden', name: 'Is Visible', render: (IsHidden, record) => (
        <EuiSwitch
          label={!IsHidden ? 'Visible' : 'Hidden'}
          checked={!IsHidden}
          onChange={(e) => UpdateIsHiddenRecord(e, record)}
        />
      )
    }
  ]

  const UpdateIsHiddenRecord = async (e, record) => {
    startLoading();

    try {
      const response = await axios.put(`${server}api/HomeSlider/update-hidden-field/${record.homeSlideId}`, { IsHidden: !e.target.checked }, config);
      if (response.status === 200) {
        filldata()
        stopLoading();
      }
    } catch (error) {
      showToast(error.toString(), "error", 15000);
      stopLoading();
    }
  };

  // delete function
  const deleteRecord = async (record) => {
    startLoading();
    try {
      const response = await axios.delete(`${server}api/HomeSlider/${record.homeSlideId}`, config);
      if (response.status === 200) {
        filldata()
        stopLoading();
        hideDialog();
        showToast("Record Deleted", "success", 2000);
      }
    } catch (error) {
      showToast(error.toString(), "error", 15000);
      stopLoading();
    }
  };

  // edit function
  const editRecord = async (record) => {
    try {
      startLoading();
      const response = await axios.get(`${server}api/HomeSlider/${record.homeSlideId}`, config);
      if (response.status === 200) {
        const data = await response.data;
        setEditData(data);
        setIsEditing(true);
        stopLoading();
      }
    } catch (error) {
      showToast(error.toString(), "error", 15000);
      console.error(error);
      stopLoading();
    }
  };

  //declare edit and delete buttons
  const actions = [
    {
      name: 'Edit',
      isPrimary: true,
      description: 'Edit this Record',
      icon: 'pencil',
      type: 'icon',
      onClick: editRecord,
      'data-test-subj': 'action-edit',
    }, {
      name: "Delete",
      description: 'Delete this Record',
      icon: 'trash',
      color: 'danger',
      type: 'icon',
      onClick: showDialog,
      isPrimary: true,
      'data-test-subj': 'action-delete',
    }
  ];

  //add buttons to columns
  const columnsWithActions = [
    ...columns,
    {
      name: 'Actions',
      actions,
    },
  ];

  //get table data
  const filldata = async () => {
    startLoading();
    try {
      var response = await axios.get(`${server}api/HomeSlider`, config);
      if (response.status === 200) {
        const data = await response.data;
        setData(data);
      }
      stopLoading();
    } catch (error) {
      showToast(error.toString(), "error", 15000);
      stopLoading();
    }
  }
  useEffect(() => {
    filldata();
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!isEditing && !isAdding) {
      filldata()
    }
    // eslint-disable-next-line
  }, [isAdding, isEditing]);

  useEffect(() => {
    if (!isEditing) {
      setEditData({});
    }
  }, [isEditing]);

  return (
    <EuiPageBody style={{ width: "100%", padding: "10px" }}>
      <Loader IsLoading={IsLoading} />
      {!isAdding && !isEditing && (
        <>
          <Header
            headerText="Image Gallery Master"
            setIsAdding={setIsAdding}
          />
          <Table data={Data} columns={columnsWithActions} height={70} />
        </>
      )}
      {isAdding && (
        <Add
          server={server}
          authtoken={authtoken}
          startLoading={startLoading}
          stopLoading={stopLoading}
          setIsAdding={setIsAdding}
        />
      )}
      {isEditing && (
        <Edit
          server={server}
          authtoken={authtoken}
          EditData={EditData}
          startLoading={startLoading}
          stopLoading={stopLoading}
          setIsEditing={setIsEditing}
        />
      )}
      <Toast />
      <ConfirmModel title="Delete Record ?" text="The Record will be Deleted. Please Proceed With Caution !" confirmButton="Delete" cancelButton="Cancel" confirmFunction={deleteRecord} />
    </EuiPageBody>
  )
}

export default Articles

import React from 'react'
import ReactLoader from "react-js-loader";

function Loader({ IsLoading }) {
    return (
        <>
            {IsLoading && <div style={{
                position: 'fixed',
                zIndex: 1000000,
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(128, 128, 128, 0.1)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <ReactLoader style={{ zIndex: "10001" }} type="box-rectangular" bgColor={"#2e466e"} size={80} />
            </div>}
        </>
    )

}

export default Loader

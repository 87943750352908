import { EuiPageSidebar, EuiCollapsibleNav, EuiListGroup, EuiListGroupItem } from '@elastic/eui'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

function Sidebar({ navIsOpen, setNavIsOpen }) {
  const navigate = useNavigate();
  const location = useLocation();
  const role = localStorage.getItem("role");

  var sidebar = [];
  if (role === "Super Admin") {
    sidebar = [
      { label: "Image Gallery", path: "image-gallery" },
      { label: "Causes", path: "causes" },
      { label: "Cause Photos", path: "cause-photos" },
      { label: "Events", path: "events" },
      { label: "Event Photos", path: "event-photos" },
      { label: "Article", path: "articles" },
      { label: "Contact Email", path: "contact-email" },
      { label: "Committee Member", path: "committee-members" },
      { label: "Donation Payments", path: "donation-payment-details" },
      { label: "Inquiries", path: "contact-us-inquiries" }
    ];
  }else if(role === "Admin") {
    sidebar = [
      { label: "Image Gallery", path: "image-gallery" },
      { label: "Cause Photos", path: "cause-photos" },
      { label: "Events", path: "events" },
      { label: "Event Photos", path: "event-photos" },
      { label: "Article", path: "articles" },
      { label: "Inquiries", path: "contact-us-inquiries" }
    ];
  }


  return (
    <div style={{ width: 0 }}>
      <EuiPageSidebar>
        <EuiCollapsibleNav
          size={240}
          isCollapsible={true}
          isOpen={navIsOpen}
          isDocked={true}
          onClose={() => setNavIsOpen(false)}
        >
          <EuiListGroup>
            <EuiListGroupItem
              iconType="dashboardApp"
              label="Dashboard"
              onClick={() => {
                navigate('')
                setNavIsOpen(!navIsOpen)
              }}
            />
            {
              sidebar.map(item => {
                return (<EuiListGroupItem
                  iconType={location.pathname.split("/").pop() === item.path ? "play" : "arrowRight"}
                  label={item.label}
                  onClick={() => {
                    navigate(item.path)
                    setNavIsOpen(!navIsOpen)
                  }}
                />)
              })
            }
            {/* <EuiListGroupItem
              iconType="play"
              label="404 Not Found"
              onClick={() => {
                navigate('404NotFound')
                setNavIsOpen(!navIsOpen)
              }}
            /> */}
          </EuiListGroup>
        </EuiCollapsibleNav>
      </EuiPageSidebar>
    </div>
  )
}

export default Sidebar

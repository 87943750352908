import React, { useEffect, useState } from 'react';
import {
    EuiButton,
    EuiFieldText,
    EuiForm,
    EuiFormRow,
    EuiPage,
    EuiPageBody,
    EuiFlexGroup,
    EuiFlexItem,
    EuiFieldPassword,
    EuiCard,
    EuiImage,
    EuiText,
    COLORS,
    EuiSelect,
} from '@elastic/eui';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Login = () => {
    const server = process.env.REACT_APP_APIPATH;
    const [Values, setValues] = useState({ username: "", password: "", role: "0" });
    const [IsInvalid, setIsInvalid] = useState({ username: false, password: false, role: false });
    const [WarningMessage, setWarningMessage] = useState("");
    const [IsLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        let authtoken = localStorage.getItem("authtoken");
        if (authtoken) {
            navigate(`/`)
        }
        // eslint-disable-next-line
    }, [])

    const onEnter = (e) => {
        if (e.key === 'Enter') {
            handleLogin()
        }
    };

    const validateControls = () => {
        let returnval = true;
        if (Values.username === "") {
            setIsInvalid(prev => { return { ...prev, username: true } })
            returnval = false;
        }

        if (Values.password === "") {
            setIsInvalid(prev => { return { ...prev, password: true } })
            returnval = false;
        }

        if (Values.role === "0") {
            setIsInvalid(prev => { return { ...prev, role: true } })
            returnval = false;
        }
        return returnval;
    }

    const handleLogin = async () => {
        try {
            if (validateControls()) {
                setIsLoading(prev => { return true });
                var response = await axios.post(`${server}api/AdminAuthentication`,
                    {
                        username: Values.username,
                        passward: Values.password,
                        Role: Values.role
                    });

                if (response.status == 200) {
                    var data = await response.data;
                    localStorage.setItem("authtoken", data.authtoken);
                    localStorage.setItem("role", Values.role);
                    navigate(`/`);
                } else {
                    setWarningMessage("Invalid Credentials! Please Try Again!");
                    shakeMessage();
                }
                setIsLoading(prev => { return false });
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response && error.response.status == 401) {
                    setWarningMessage("Invalid Credentials! Please Try Again!");
                    shakeMessage();
                }
            }
            console.error(error);
            setIsLoading(prev => { return false });
            shakeMessage();
        }
    }

    const shakeMessage = () => {
        const errorMessageElement = document.getElementById('error-message');
        errorMessageElement.classList.add('error-message');

        setTimeout(() => {
            errorMessageElement.classList.remove('error-message');
        }, 500);
    }

    const onChangehandler = (e) => {
        setValues({ ...Values, [e.target.name]: e.target.value })
        if (e.target.value !== "") {
            setIsInvalid({ ...IsInvalid, [e.target.name]: false })
        }
    }

    return (
        <EuiPage>
            <EuiPageBody component="div">
                <EuiFlexGroup
                    alignItems="center"
                    justifyContent="center"
                    style={{ height: '100vh' }}
                >
                    <EuiFlexItem grow={false} style={{ width: '100%', maxWidth: '350px', padding: "10px" }}>
                        <EuiImage src='../Assets/images/ROSHNI-LOGO-MINI.png' style={{ width: "150px" }} />
                        <EuiCard
                            textAlign="center"
                            title="Roshni Education Trust"
                            description="Admin Dashboard">
                            <EuiForm component="form">
                                <EuiFormRow label="Username" isInvalid={IsInvalid.username} helpText={IsInvalid.username && "Please Enter Username"} fullWidth>
                                    <EuiFieldText
                                        icon="user"
                                        name='username'
                                        value={Values.username}
                                        onChange={onChangehandler}
                                        onKeyDown={onEnter}
                                        isInvalid={IsInvalid.username}
                                        placeholder='Your Username'
                                        fullWidth
                                    />
                                </EuiFormRow>
                                <EuiFormRow label="Password" isInvalid={IsInvalid.password} helpText={IsInvalid.password && "Please Enter Password"} fullWidth>
                                    <EuiFieldPassword
                                        name='password'
                                        value={Values.password}
                                        onChange={onChangehandler}
                                        onKeyDown={onEnter}
                                        isInvalid={IsInvalid.password}
                                        type="dual"
                                        placeholder='********'
                                        fullWidth
                                    />
                                </EuiFormRow>
                                <EuiFormRow label="Role" isInvalid={IsInvalid.role} helpText={IsInvalid.role && "Please Select Role"} fullWidth>
                                    <EuiSelect
                                        name='role'
                                        options={[
                                            {
                                                value: "0",
                                                text: '-- SELECT ROLE --',
                                            },
                                            {
                                                value: 'Super Admin',
                                                text: 'Super Admin',
                                            },
                                            {
                                                value: 'Admin',
                                                text: 'Admin',
                                            }
                                        ]}
                                        value={Values.role}
                                        isInvalid={IsInvalid.role}
                                        onChange={onChangehandler}
                                        onKeyDown={onEnter}
                                        fullWidth
                                    />
                                </EuiFormRow>
                                <EuiText color={COLORS[5]} id='error-message'>
                                    <p>{WarningMessage}</p>
                                </EuiText>
                                <br />
                                <EuiFormRow fullWidth >
                                    <EuiButton onClick={handleLogin} fill fullWidth isDisabled={IsLoading} isLoading={IsLoading} >
                                        Log In
                                    </EuiButton>
                                </EuiFormRow>
                            </EuiForm>
                        </EuiCard>
                    </EuiFlexItem>
                </EuiFlexGroup>
            </EuiPageBody>
        </EuiPage>
    );
};

export default Login;

import { EuiButton, EuiImage, EuiSpacer, EuiText } from '@elastic/eui'
import React from 'react'
import { NavLink } from 'react-router-dom'

function NotFound() {

    return (
        <main id="start-of-content" className="css-15iaoty-euiPageInner-panelled-top">
            <section className="css-68douo-euiPageSection-grow-l-center-transparent">
                <div className="css-1sghhs8-euiPageSection__content-l-center">
                    <div className="euiPanel euiPanel--subdued euiEmptyPrompt euiEmptyPrompt--vertical euiEmptyPrompt--paddingLarge css-1wqem1z-euiPanel-m-subdued">
                        <div className="euiEmptyPrompt__main">
                            <div className="euiEmptyPrompt__icon">
                                <EuiImage src='https://eui.elastic.co/images/3debad008db0a67b9696-pageNotFound--light.png' />
                            </div>
                            <div className="euiEmptyPrompt__content">
                                <div className="euiEmptyPrompt__contentInner">
                                    <EuiText><h2>Page not found</h2></EuiText>
                                    <EuiSpacer />
                                    <div className="euiFlexGroup euiEmptyPrompt__actions css-17kdkag-euiFlexGroup-m-center-center-column">
                                        <div className="euiFlexItem css-kpsrin-euiFlexItem-growZero">
                                            <NavLink
                                                to=""
                                                rel="noreferrer"
                                            >
                                                <EuiButton>
                                                    Go home
                                                </EuiButton>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}

export default NotFound

import React, { useEffect, useState } from 'react'
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Dashboard from "./Pages/Dashboard";
import Login from "./Pages/Login";
import ImageGallery from "./Pages/ImageGallery";
import Events from "./Pages/Events";
import EventPhotos from "./Pages/EventPhotos";
import CausePhotos from "./Pages/CausePhotos";
import ContactEmail from "./Pages/ContactEmail";
import Articles from "./Pages/Articles";
import CommitteeDetails from "./Pages/CommitteeDetails";
import PaymentDetails from "./Pages/PaymentDetails";
import Inquiry from "./Pages/Inquiry";
import Toast from "./Components/Toast";
import Causes from './Pages/Causes';
import Header from './Components/Header';
import { EuiPage } from '@elastic/eui';
import Sidebar from './Components/Sidebar';
import NotFound from './Pages/NotFound';
import ChangePassword from './Pages/ChangePassword';
import TinyMceEditor from './Pages/Causes/TinyMceEditor';

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [navIsOpen, setNavIsOpen] = useState(false);
  const role = localStorage.getItem("role");;

  useEffect(() => {
    const authtoken = localStorage.getItem("authtoken");
    if (authtoken) {
      setIsAuthenticated(true);
      navigate(location.pathname);
    } else {
      navigate(`login`);
    }
    // eslint-disable-next-line
  }, [navigate]);

  return (
    <>
      <EuiPage>
        {
          isAuthenticated ?
            (<>
              <Header isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} navIsOpen={navIsOpen} setNavIsOpen={setNavIsOpen} />
              <Sidebar navIsOpen={navIsOpen} setNavIsOpen={setNavIsOpen} />
              <div className='main'>
                <div style={{ padding: "10px" }}>
                  <Routes>
                    <Route exact path={`/`} element={<Dashboard />} />
                    <Route exact path={`/image-gallery`} element={<ImageGallery />} />
                    {role === "Super Admin" && <Route exact path={`/causes`} element={<Causes />} />}
                    {role === "Super Admin" && <Route exact path={`/causes/:id/page`} element={<TinyMceEditor />} />}
                    <Route exact path={`/cause-photos`} element={<CausePhotos />} />
                    <Route exact path={`/events`} element={<Events />} />
                    <Route exact path={`/event-photos`} element={<EventPhotos />} />
                    <Route exact path={`/articles`} element={<Articles />} />
                    {role === "Super Admin" && <Route exact path={`/committee-members`} element={<CommitteeDetails />} />}
                    {role === "Super Admin" && <Route exact path={`/contact-email`} element={<ContactEmail />} />}
                    {role === "Super Admin" && <Route exact path={`/donation-payment-details`} element={<PaymentDetails />} />}
                    <Route exact path={`/contact-us-inquiries`} element={<Inquiry />} />
                    <Route exact path={`/change-password`} element={<ChangePassword isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />} />
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </div>
              </div>
            </>) :
            (<Login />)
        }
        <Toast />
      </EuiPage>
    </>
  )
}

export default App
